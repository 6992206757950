import createAPI from "@/utils/axios"

const Home = {
  Login: (uid, id) => createAPI(`/v1/user/h5-login?uid=${uid}&enterprise_id=${id}`, "GET", {}),
  checkLogin: () => createAPI(`/v1/user/check-login`, "GET", {}),
  webAuth: (type, id, appid, callback) => createAPI(`/v1/user/web-auth?auth_type=${type}&tid=124&enterprise_id=${id}&appid=${appid}&callback=${callback}`, "GET", {}),
  phoneOrder: (config) => createAPI(`/v1/user/phone-order`, "PUT", config),
  assessmentInfo: (type, id) => createAPI(`/v1/dimension/assessment-info?type=${type}&tid=${id}`, "GET", {}),
  submit: (config) => createAPI(`/v1/dimension/submit`, "POST", config),
  result: (tid) => createAPI(`/v1/dimension/result?tid=${tid}`, "GET", {}),
  needPhone: (tid, type) => createAPI(`/v1/user/need-phone?tid=${tid}&type=${type}`, "GET", {}),
  bindChannel: (config) => createAPI(`/v1/user/bind-channel`, "PUT", config),
  // getSources: () => createAPI(`/v1/user/get-sources`, "GET", {}),

}

export default Home