<template>
  <div>
    <router-view/>
  </div>

</template>

<style>
@import url(./assets/css/base.css);
@import url(./assets/css/reset.css);

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F4F6;
  height: 100%;
}
</style>
