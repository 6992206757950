import { createApp } from 'vue'
import { Toast } from 'vant'

import App from './App.vue'
import router from './router'
import api from "./api/index"

import 'amfe-flexible'
const app = createApp(App)
/* eslint-disable */
// import VConsole from "vconsole"
// var vConsole = new VConsole()
/* eslint-disable */

app.use(router).use(Toast).mount('#app')



app.config.globalProperties.$api = api
app.config.globalProperties.$router = router
