import service from "./request"

const createAPI = (url, method, config = {}) => {
  return service({
    url,
    method,
    ...config
  })
}

export default createAPI