import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "test" */ '../views/Auth.vue')
  },
  {
    path: '/entry',
    name: 'Entry',
    component: () => import(/* webpackChunkName: "entry" */ '../views/Entry.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import(/* webpackChunkName: "result" */ '../components/result.vue')
  },
  {
    path: '/resultDefault',
    name: 'ResultDefault',
    component: () => import(/* webpackChunkName: "result" */ '../components/resultDefault.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
