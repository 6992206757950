import axios from 'axios'
import { getToken } from '@/utils/auth'
import router from "@/router/index"

import { createApp } from 'vue';
import { Toast } from 'vant';

const app = createApp();
app.use(Toast);

// 创建axios实例
console.log(process.env.NODE_ENV)
let baseUrl = ""
if (process.env.NODE_ENV === "production") {
  baseUrl = "https://evalapi.xajkzg.com"
} else {
  baseUrl = "https://evalapi.xajkzg.com"
}
const service = axios.create({
  baseURL: baseUrl, // api 的 base_url
  timeout: 10000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  config => {
    // console.info("getToken()",getToken())
    if (getToken()) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }else{
      config.headers['token'] =  localStorage.getItem("token")
    }
    // console.info("request拦截器-config",localStorage.getItem("token"))
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    console.log("response", response.data.code)
    if (response.status === 200) {
      if (response.data.code === 200) {
        return Promise.resolve(response.data)
      } else if (response.data.code === 100001) {
        Toast.fail("登录已过期，重新登录")
        localStorage.clear()
        router.push('/')
        return Promise.reject(response.data)
      } else if (response.data.code === -10000) {
        Toast.fail(response.data.message)
        return Promise.reject(response.data)
      } else {
        return Promise.reject(response.data)
      }
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
export default service